<template>
  <div class="about">
    <PageHeader />
    <div class="header">
      <Header />
      <div class="header-text">
        <div class="header-title">
          关于我们
        </div>
        <div class="header-sign">
          高收益流量价值探索者
        </div>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <div class="item">
          <div class="item-name">
            公司介绍
          </div>
          <img
            src="../img/about-icon.png"
            alt=""
            class="item-icon"
          >
          <div class="item-desc">
            新量象流量价值平台隶属于杭州新量象网络科技有限公司。
            <div>公司前身为杭州小啄科技有限公司，成立于2017年8月，通过两年多的努力，平台试玩总用户量已突破1200万人。出于业务发展的需求，2019年12月正式升级为杭州新量象网络科技有限公司，新量象作为旗下媒体流量价值平台，在流量主维持现有规模发展的前提下，额外挖掘流量价值，实现流量利益最大化。只要您有流量，就能获得额外收益。</div>
          </div>
        </div>
        <!-- 旗下项目 -->
        <!-- <div class="item">
          <div class="item-name">
            旗下项目
          </div>
          <img
            src="../img/about-icon.png"
            alt=""
            class="item-icon"
          >
          <div class="term-list">
            <div
              class="term-item"
              v-for="(item, index) in termList"
              :key="index"
            >
              <img
                :src="item.icon"
                alt=""
                class="term-item-icon"
              >
              <div class="term-item-name">
                {{ item.name }}
              </div>
              <div class="term-item-desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div> -->
        <PageFooter />
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  data () {
    return {
      termList: [
        {
          name: '小啄赚钱',
          icon: require('../img/term-1.png'),
          desc: '一款用手机兼职做任务赚零钱的APP，用户可以通过试玩应用获得现金奖励。'
        },
        {
          name: '鱼玩盒子',
          icon: require('../img/term-2.png'),
          desc: '鱼玩盒子对接大量游戏广告主，用户通过玩游戏实现APP媒体、用户、鱼玩 三方共赢。'
        },
        // {
        //   name: '南瓜小游戏',
        //   icon: require('../img/term-3.png'),
        //   desc: '一款休闲游戏平台，海量游戏无需下载随时玩，玩转1小时，瓜分现金1万元。'
        // },
        {
          name: '左玩右玩',
          icon: require('../img/term-4.png'),
          desc: '一款用手机兼职做任务赚零钱的APP，拥有丰富的任务类型和海量任务。'
        },
        {
          name: '分贝赚钱',
          icon: require('../img/term-5.png'),
          desc: '新量象和PP视频联运的一个APP，用户通过玩游戏产生收益，任务多奖励丰厚。'
        }
      ]  
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url('../img/about-bg.png');
  background-size: cover;
  }
  .header-text{
    position: absolute;
    top: 166px;
    left: 260px;
  }
  .header-title{
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 30px;
    color: #fff;
    // font-family: 'fzqk';
  }
  .header-sign{
    font-size: 24px;
    line-height: 24px;
    color: #fff;
  }
.content{
  width: 100%;
  background: #fff;
  padding: 0 260px;
  box-sizing: border-box;
  overflow: hidden;
}
.list{
  margin-top: 60px;
}
.item{
  &-name{
    font-size:30px;
    // font-family:'fzqk';
    color:rgba(102,102,102,1);
    line-height:30px;
    margin-bottom: 28px;
  }
  &-icon{
    width: 45px;
    height: 8px;
    margin-bottom: 31px;
  }
  &-desc{
    font-size:18px;
    color:rgba(102,102,102,1);
    line-height:36px;
    margin-bottom: 90px;
    text-indent: 36px;
  }
  &-p{
    display: flex;
    align-items: center;
    font-size:18px;
    color:rgba(153,153,153,1);
    line-height:18px;
    &-title{
      margin-right: 30px;
    }
  }
}
.term-list{
  display: flex;
  align-items: center;
  margin-bottom: 120px;
}
.term-item{
  width:210px;
  height:288px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 14px 0px rgba(4,0,0,0.06);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin-right: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  flex: none;
  &-icon{
    width: 90px;
    height: 90px;
    margin-top: 30px;
    margin-bottom: 17px;
    flex: none;
  }
  &-name{
    font-size:24px;
    color:rgba(255,132,0,1);
    line-height:24px;
    margin-bottom: 8px;
    // font-family: 'fzqk';
  }
  &-desc{
    font-size:14px;
    color:rgba(102,102,102,1);
    line-height:30px;
  }
}
.item-contact{
  margin-bottom: 60px;
}
@media screen and (max-width: 1440px) {
.header{
  height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
.list{
  margin-top: 40px;
}
.content{
  padding: 0 195px;
}
.item{
  &-name{
    font-size:22px;
    line-height:22px;
    margin-bottom: 24px;
  }
  &-icon{
    width: 32px;
    height: 6px;
    margin-bottom: 23px;
  }
  &-desc{
    font-size:13px;
    line-height:27px;
    margin-bottom: 73px;
  }
  &-p{
    font-size:13px;
    &-title{
      margin-right: 20px;
    }
  }
}
.term-list{
  margin-bottom: 90px;
}
.term-item{
  width:158px;
  height:216px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 11px 0px rgba(4,0,0,0.06);
  margin-right: 37px;
  padding: 0 13px;
  &-icon{
    width: 68px;
    height: 68px;
    margin-top: 23px;
    margin-bottom: 13px;
    flex: none;
  }
  &-name{
    font-size:18px;
    line-height:18px;
    margin-bottom: 4px;
  }
  &-desc{
    font-size:11px;
    line-height:22px;
  }
}
  .item-contact{
    margin-bottom: 46px;
  }
}

</style>